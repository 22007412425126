<template>
  <div v-loading="loading">
    <el-divider content-position="left">基本信息</el-divider>
    <el-row>
      <el-col :span="6">
        <div class="grid-content bg-purple">
          <el-form ref="form" label-width="100px">
            <el-form-item label="项目编号">
              {{ InfoData.project_sn }}
            </el-form-item>
            <el-form-item label="项目名称">
              {{ InfoData.project_title }}
            </el-form-item>
            <el-form-item label="项目成单金额">
              {{ InfoData.project_price ? (InfoData.project_price.deal_all_price / 10000).toFixed(2) : 0 }}万
            </el-form-item>
            <el-form-item label="计划起止时间">
              {{ Utils.timeDate(InfoData.start_time) }} 至 {{ Utils.timeDate(InfoData.end_time) }}
            </el-form-item>

          </el-form>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="grid-content bg-purple-light">
          <el-form ref="form" label-width="160px">
            <el-form-item label="项目实际编号">
              <span>
                {{ a1 }}
              </span>
            </el-form-item>

            <el-form-item label="商务负责人">
              <span>
                {{ InfoData.user ? InfoData.user.name : '无' }}
              </span>
            </el-form-item>
            <el-form-item label="技术负责人">
              <span>
                {{ InfoData.dev_user ? InfoData.dev_user.name : '无' }}
              </span>
            </el-form-item>
            <el-form-item label="实收总金额">
                <span v-if="InfoData.project_price">
                  {{ (InfoData.project_price.real_all_price / 10000).toFixed(2) || 0 }}万
              </span>
              <span v-else>
                无
              </span>
            </el-form-item>
          </el-form>
        </div>
      </el-col>
    </el-row>
    <el-divider content-position="left">收款信息</el-divider>
    <el-row>
      <el-col :span="24">
        <div class="grid-content bg-purple">
          <!--              show-summary-->
          <el-table
              :data="InfoData.shou_kuan_ji_hua"
              border
              style="width: 100%">


            <el-table-column
                header-align="center"
                align="center"
                label="期数"
                type="index"
            >
            </el-table-column>

            <el-table-column
                header-align="center"
                align="center"
                label="金额(万元)"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.expect_amount }}</span>
              </template>
            </el-table-column>

            <el-table-column
                header-align="center"
                align="center"
                label="实收款（万元）"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.actual_amount }}</span>
              </template>
            </el-table-column>

            <el-table-column
                header-align="center"
                align="center"
                label="实收日期"
            >
              <template slot-scope="scope">
                <span>{{
                    scope.row.actual_receive_time ? Utils.timeDate(Math.trunc(scope.row.actual_receive_time)) : ''
                  }}</span>
              </template>
            </el-table-column>

          </el-table>
        </div>
      </el-col>

    </el-row>

    <el-divider content-position="left">分红结算</el-divider>
    <el-row>
      <el-col :span="24">
        <div class="grid-content bg-purple">
          <el-table
              :data="dividendSettlementData"
              border
              style="width: 100%"

          >
            <el-table-column
                prop="a1"
                label="累计计算分红基数金额(万元)"
                align="center"
            >
            </el-table-column>
            <el-table-column
                prop="a2"
                label="日期"
                align="center"
            >
              <template slot-scope="scope">
                {{ Utils.timeDate(scope.row.a2) }}
              </template>
            </el-table-column>

          </el-table>
        </div>
      </el-col>

    </el-row>

    <el-divider content-position="left">商务成本</el-divider>
    <el-row>
      <el-col :span="24">
        <div class="grid-content bg-purple">
          <el-form ref="form" label-width="180px">
            <el-form-item label="居间成本">
              <span>
                <span style="padding: 0 60px">{{ InfoData.project_price.source_cost_rate }}% </span>
                {{InfoData.project_price.source_cost_money}} 万元
              </span>
            </el-form-item>
            <el-form-item label="实际外部协作成本比例">
              <span>
                 <span style="padding: 0 60px">{{InfoData.project_price.external_cost_rate}}% </span>
                {{InfoData.project_price.external_cost_money}} 万元
              </span>
            </el-form-item>
            <el-form-item label="市场及商务投入成本合计">
              <span v-if="InfoData.reimburseMoneyCount">
                <span style="padding: 0 60px">{{ InfoData.marketPushCostRate }}% </span>
                {{ InfoData.marketPushCostSum }}      万元

              </span>
              <span v-else>
                  <span style="padding: 0 60px">0</span>     0

              </span>
            </el-form-item>
          </el-form>
          <el-table
              :data="InfoData.reimburse"
              border
              show-summary
              style="width: 100%">
            <el-table-column
                prop="user_info.name"
                label="姓名"
                align="center"
            >
            </el-table-column>
            <el-table-column
                prop="user_station.department_name"
                label="部门"
                align="center"
            >
            </el-table-column>
            <el-table-column
                prop="user_station.station_name"
                align="center"
                label="岗位">
            </el-table-column>
            <el-table-column
                prop="type_name"
                align="center"
                label="报销类型">
            </el-table-column>
            <el-table-column
                prop="all_price"
                align="center"
                label="报销金额">
            </el-table-column>
            <el-table-column
                prop="goodsSum"
                align="center"
                label="物资使用金额">
            </el-table-column>


          </el-table>
        </div>
      </el-col>

    </el-row>
    <el-divider content-position="left">分红明细</el-divider>
    <el-row>
      <el-col :span="24">
        <div class="grid-content bg-purple">
          <ul class="dividend_details">
            <li>
              市场实际分红比例：{{InfoData.market_red_rate  + '%' }}
            </li>
            <li>
              市场实际分红总额：
              {{ InfoData.project_mc.all_red_money }} 元
            </li>
            <li>已发放分红金额：{{ InfoData.project_mc ? InfoData.project_mc.had_send_red_money : 0 }} 元</li>
            <li>未发放分红金额：{{ InfoData.project_mc ? InfoData.project_mc.no_send_red_money : 0 }} 元</li>
          </ul>
          <el-table
              :data="InfoData.project_mc?InfoData.project_mc.real_member:[]"
              border
              style="width: 100%"
              class="dividend_details_table"
          >

            <el-table-column
                fixed
                prop="user.name"
                align="center"
                label="姓名">
            </el-table-column>
            <el-table-column
                prop="user_station.department_name"
                label="部门">
            </el-table-column>
            <el-table-column
                prop="user_station.station_name"
                align="center"
                label="岗位">
            </el-table-column>
            <el-table-column
                align="center"
                label="分红比例">
              <template slot-scope="scope">
                {{ (parseFloat(scope.row.red_rate) * 100).toFixed(2) }}%
              </template>
            </el-table-column>
            <el-table-column
                align="center"
                label="分红金额（元）"
                width="400px"
            >
              <template slot-scope="scope">
                <ul class="caicai-dividend">
                  <li v-for="(item,key) in scope.row['red_records']?scope.row['red_records']:[]" :key="key">
                    <div><span>分红金额{{ key + 1 }}：</span><i class="s1">{{ item.money }}</i></div>
                    <div><span>分红日期{{ key + 1 }}：</span><i class="s2">{{ Utils.timeDate(item.date) }}</i></div>
                  </li>
                </ul>
              </template>
            </el-table-column>


          </el-table>

          <ul class="dividend_details">
            <li>技术实际分红比例：{{ InfoData.dev_red_rate + '%' }}</li>
            <li>
              技术实际分红总额：{{ InfoData.project_dc.all_red_money }} 元
            </li>
            <li>已发放分红金额：{{ InfoData.project_dc ? InfoData.project_dc.had_send_red_money : 0 }} 元</li>
            <li>未发放分红金额：{{ InfoData.project_dc ? InfoData.project_dc.no_send_red_money : 0 }} 元</li>
          </ul>

          <!--技术分红表格-->
          <el-table
              :data="InfoData.project_mc?InfoData.project_dc.real_member:[]"
              border
              style="width: 100%">
            <el-table-column
                fixed
                prop="user.name"
                align="center"
                label="姓名">
            </el-table-column>
            <el-table-column
                prop="user_station.department_name"
                align="center"
                label="部门">
            </el-table-column>
            <el-table-column
                prop="user_station.station_name"
                align="center"
                label="岗位">
            </el-table-column>
            <el-table-column
                align="center"
                label="分红比例">
              <template slot-scope="scope">
                {{ (parseFloat(scope.row.red_rate) * 100).toFixed(2) || 0 }}%
              </template>
            </el-table-column>
            <el-table-column
                align="center"
                label="分红金额（元）"
                width="400px"
            >
              <template slot-scope="scope">
                <ul class="caicai-dividend">
                  <li v-for="(item,key) in scope.row['red_records']?scope.row['red_records']:[]" :key="key">
                    <div><span>分红金额{{ key + 1 }}：</span><i class="s1">{{ item.money }}</i></div>
                    <div><span>分红日期{{ key + 1 }}：</span><i class="s2">{{ Utils.timeDate(item.date) }}</i></div>
                  </li>
                </ul>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>

    </el-row>
    <el-divider content-position="left">人工成本</el-divider>
    <el-row>
      <el-col :span="24">
        <div class="grid-content bg-purple">
          <el-table
              :data="InfoData.laborCost?InfoData.laborCost.list:[]"
              border
              show-summary
              style="width: 100%">
            <el-table-column
                fixed
                prop="user.name"
                align="center"
                label="姓名">
            </el-table-column>
            <el-table-column
                prop="user_station.department_name"
                align="center"
                label="部门">
            </el-table-column>
            <el-table-column
                prop="user_station.station_name"
                align="center"
                label="岗位">
            </el-table-column>
            <el-table-column
                align="center"
                label="工期">
              <template slot-scope="scope">
                {{ Utils.timeDate(scope.row.real_start_time) }} 至 {{ Utils.timeDate(scope.row.real_end_time) }}
              </template>
            </el-table-column>
            <el-table-column
                prop="work_fee"
                align="center"
                label="工期工资">
            </el-table-column>
          </el-table>
        </div>
      </el-col>

    </el-row>
  </div>

</template>

<script>
import {moneySettleDetail} from "../../../api/project_management/new_project";

export default {
  components: {},
  name: "project_info",
  data() {
    return {
      id: 0,
      loading: true,
      a1: '无',//项目实际编号
      //分红结算
      dividendSettlementData: [
        /* {
           a1:'',//累计计算分红基数金额
           a2:''//日期 10位数时间戳
         }*/
      ],
      InfoData: []
    }
  },
  created() {
    this.id = this.$route.query.id
    this.initData()
  },
  mounted() {
  },
  methods: {
    initData() {
      this.loading = true;
      let data = {project_id: this.id}
      moneySettleDetail(data).then(res => {
        this.InfoData = res.data
        // this.InfoData.project_dc.real_red_rate = this.InfoData.project_dc ==null?0: this.InfoData.project_dc.real_red_rate
        // this.InfoData.project_mc.real_red_rate = this.InfoData.project_mc ==null?0: this.InfoData.project_mc.real_red_rate

        // user_salary.
        for (let i = 0; i < this.InfoData.laborCost.list.length; i++) {
          this.InfoData.laborCost.list[i].user_salary_num = this.InfoData.laborCost.list[i].user_salary.base_salary
        }
        //分红结算
        this.dividendSettlementData = res.data.red_records.map(item => ({
          a1: (item.price / 10000).toFixed(2),//累计计算分红基数金额
          a2: item.date//日期 10位数时间戳
        }));
        this.loading = false;


        for (let i = 0; i < this.InfoData.reimburse.length; i++) {
          switch (this.InfoData.reimburse[i].type) {
            case 1:
              this.InfoData.reimburse[i].type_name = '普通报销';
              break;
            case 2:
              this.InfoData.reimburse[i].type_name = '差旅费报销';
              break;
          }
        }

        console.log(this.InfoData)
      })

    }
  }
}
</script>

<style lang="scss" scoped>
.dividend_details {
  @include flex();
  font-size: 14px;

  li {
    margin-right: 20px;
  }

  margin-bottom: 20px;
}

.dividend_details_table {
  margin-bottom: 20px;
}

.el-divider {
  background: rgb(58, 102, 226);
}

.el-divider__text {
  color: rgb(58, 102, 226);
}

.grid-content {
  padding: 30px;
}

.caicai-dividend {
  li {
    @include flex();
    border-bottom: 1px solid #dadada;
    padding: 8px 0;

    div:nth-of-type(1) {
      margin-right: 15px;
      line-height: 25px;
    }

    span {
      display: inline-block;
      color: #666;
      width: 80px;
      text-align: right;
    }

    i {
      display: inline-block;
      text-align: left;
      width: 70px;
      color: #333;
    }

    .s1 {
      color: red;
    }
  }

  li:nth-last-of-type(1) {
    border-bottom: 0;
  }

}
</style>
